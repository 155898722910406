<script lang="ts">
import { getCmsImage } from '@/composables/useCms';

interface Cat {
  title: string;
  uri: string;
  image: { url: string }[];
}

export default defineComponent({
  props: {
    heading: {
      type: String,
      default: null,
    },
    categories: {
      type: Array as PropType<Cat[]>,
      default: () => [],
    },
  },
  setup() {
    const localePath = useLocalePath();

    // Define grid classes based on index
    const getGridClass = (index: number): string => {
      const gridClasses = [
        "md:col-span-2 md:row-span-2 lg:col-span-2 lg:row-span-2 h-[300px] md:h-[400px]",
        "md:col-span-2 md:row-span-1 lg:col-span-2 lg:row-span-1 h-[300px] md:h-[188px]",
        "md:col-span-2 md:row-span-1 lg:col-span-2 lg:row-span-1 h-[300px] md:h-[188px]",

        "md:col-span-2 md:row-span-2 lg:col-span-1 lg:row-span-1 h-[300px] md:h-[400px] lg:h-[188px]",
        "md:col-span-2 md:row-span-2 lg:col-span-1 lg:row-span-1 h-[300px] md:h-[400px] lg:h-[188px]",
        "md:col-span-2 md:row-span-2 lg:col-span-2 lg:row-span-2 h-[300px] md:h-[400px]",
        "md:col-span-2 md:row-span-2 lg:col-span-2 lg:row-span-1 h-[300px] md:h-[400px] lg:h-[188px]",
      ];
      return gridClasses[index % gridClasses.length] || "col-span-1 row-span-1";
    };

    return {
      localePath,
      getCmsImage,
      getGridClass,
    };
  },
});
</script>

<template>
  <v-container>
    <h2 class="text-2xl mb-4 font-bold text-gray-900">
      {{ heading }}
    </h2>
    <div class="grid grid-cols-1 md:grid-cols-4 md:grid-rows-4 gap-6">
      <NuxtLink
        v-for="(category, index) in categories"
        :key="`${category.uri}-${index}`"
        :to="localePath('/' + category.uri)"
        class="relative group overflow-hidden h-full rounded"
        :class="getGridClass(index)"
      >
        <NuxtImg 
          :alt="category.title" 
          :src="getCmsImage(category.image)"
          format="webp"
          loading="lazy"
          class="object-cover w-full object-center h-full transform scale-110 transition-transform duration-500 linear group-hover:scale-100" 
        />
        <div aria-hidden="true" class="absolute inset-0 bg-gradient-to-t from-black from-[-20%] to-[40%]"></div>
        <div class="flex items-end p-6 absolute inset-0 text-white">
          <div>
            <h3 class="font-semibold text-white text-2xl">{{ category.title }}</h3>
            <div v-if="category.description" v-html="category.description"></div>
          </div>
        </div>
      </NuxtLink>
    </div>
  </v-container>
</template>
